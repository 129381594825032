// Here you can add other styles

.ant-steps-item-icon, .ant-steps-item-content {
  display: table-caption;
}

.btn  .ant-btn .ant-btn-primary{
  background: #f20000 !important;
  color: white;
  border: none;
  border-radius: 3px;
}

.btn-light {
  background-color: #fff !important;
  color: #f20000;
  border: solid thin #f20000 !important;
}

.btn-light:focus .btn-light:hover .btn-light:active {
  color: #5a0303 !important;
  border: solid thin #f20000 !important;
  background-color: #fff !important;
}


.btn:disabled, .btn-primary:disabled, .ant-btn-primary:disabled {
  background-color: #f20000;
  opacity: 0.65;
}

.btn:active, .btn-primary:active, .ant-btn-primary:active {
  background-color: #d80202;
  border: none;
  box-shadow: 0px 0px 0px 0.2rem #f2000050;
}

.btn:focus, .btn-primary:focus, .ant-btn-primary:focus {
  box-shadow: none;
  background-color: #f20000;
}

.btn:hover, .btn-primary:hover, .ant-btn-primary:hover {
  background-color: #d80202;
}

.ant-btn:hover, .ant-btn:focus {
  color: #fff;
  border-color: #d80202;
  background-color: #f20000;
}

.ant-input:focus, .ant-input-focused {
  border-color: rgba(0, 0, 0, 0.4);
  box-shadow: none;
}

.ant-input:hover {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.ant-upload.ant-upload-drag:hover {
  border-color: rgba(0, 0, 0, 0.4) !important;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: black;
}

.ant-steps-item-custom .ant-steps-icon {
  color: black !important;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.2) !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: black;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::before {
  background-color: rgba(0, 0, 0, 0.2);
}

.ant-col-8-sig {
  display: grid;
}

.btn-ant-prox {
  display: flex;
  justify-content: space-between;
}

.btn-env-assinatura {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media only screen and (max-width: 400px) {
  
  .div-option-user-edt-rmv {
    display: flex;
    justify-content: flex-end;
  }

  .ant-col-8-doc {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  .li-display-bk {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    flex-wrap: nowrap;
    
  }
  
  .ant-row {
    display: block;
  }

  .row-col-span {
    max-width: 100%;
  }

  .ant-col-8-sig {
    max-width: 100%;
    display: grid;
    margin-left: 0;
    text-align: center;
    align-items: center;
  }

  .ant-steps-item-icon, .ant-steps-item-content {
    display: table-caption;
  }

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    white-space: normal;
    text-align: center;
  }

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  #footer-main-text {
    text-align: center;
    width: 100%;
  }

  .ant-col-8-doc {
    flex-direction: column;
    flex: 100%;
    max-width: 100%;
  }

  #footer-secondary-text {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  #footer-main-text {
    text-align: center;
    width: 100%;
  }

  #footer-secondary-text {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  #footer-main-text {
    width: unset;
    align-self: center;
  }

  #footer-secondary-text {
    display: unset;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.sidebar .nav-link .nav-icon{
  color: #F20000 ;
}
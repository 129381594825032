.wrapper {
    max-height: 400px !important;
  }

.app-header {
    background: #ffffff !important;
}

.ant-spin-dot-item {
    background-color: #f20000 !important;
}

.ant-btn-sm {
    border-radius: 5px !important;
}

.ant-btn-primary {
    background-color: #f20000 !important;
    border: none !important;
    border-radius: 5px !important;
}

.ant-btn-primary:hover {
    background-color: #b10101 !important;
    border: none !important;
    border-radius: 5px !important;
}

.input-text-login {
   text-align: center;
   width: 100%;
   border-color: #ddd !important;
}

.input-text-login:hover {
    border-color: #ff7373 !important;
}

.input-text-login:focus-visible {
    border-color: transparent !important;
}

.input-text-login:focus {
    border-color: #ff7373 !important;
    box-shadow: 0 0 0 0.2rem rgb(242 0 0 / 25%) !important;
    outline: none;
}

.sidebar {
    color: #F20000 !important;
    background: #FFF !important;
    border: 0px !important;
}

.app-body .sidebar {
    flex: 0 0 200px;
    order: -1;
}

.sidebar .nav-dropdown.open {
    background: #FFF !important;
    border-right: 4px solid #F20000;
}

.sidebar .nav-link {
    color:#F20000 !important;
    background: transparent !important;
}

.sidebar .sidebar-minimizer {
    background: #FFF !important;
}

/* .sidebar .nav-link .nav-icon{
    color: #F20000 !important;
} */

.sidebar .nav-link:hover{
    color: #FFF !important;
    font-weight: 500 !important;
    background-color: #F20000 !important;
}

.app-header .navbar-toggler .navbar-toggler-icon .icon-bar{
    color: #F20000 !important;
    border-right: 1px solid #CCC;
}

.sidebar-minimized .sidebar .nav .nav-dropdown .nav-dropdown-items a:active {
    color: #F20000 !important;
}

.sidebar-minimized .sidebar .nav .nav-dropdown .nav-dropdown-items {
    color: #F20000 !important;
    background: #FFF !important;
}

/*## ICON MENU ##*/
.app-header .navbar-toggler-icon {
    height: 23px;
    background-image: url( 
        "data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(242, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important; 
}

.navbar-toggler {
    border: 0 solid transparent !important;
}

/*## BREADCRUMB ##*/
.breadcrumb {
    border: 1px #F0F0F0 solid!important;
}

.active.breadcrumb-item {
    color: #000 !important;
    font-weight: 400;
}

.breadcrumb-item a {
    color: #F20000 !important;
}

/*## FOOTER ##*/
.app-footer {
    background: #f20000 !important;
    color: #FFF !important;
    border-top: 1px solid #c8ced3;
}

/*## MAIN ##*/
main {
    display: block;
    background: rgb(240, 240, 240) !important;
}

/*## CARD ##*/
.card-header {
    background-color: #FFF !important;
    text-transform: uppercase !important;
    color: #f20000 !important;
    border-bottom: 8px #F0F0F0 solid !important;
}

.card {
    border: 0px !important;
    border-radius: 20px !important;
    box-shadow: 10px 10px 10px 10px #e5e5e5 !important;
}

/*## CARD NEW ##*/
.badge {
    display: none !important;
}

/*## CARD MENSAGEM ##*/
.ant-message-notice-content {
    display: inline-block;
}
.ant-message {
    z-index: 100000 !important;
}

@media only screen and (max-width: 400px) {
    .chart-deshboard {
        height: 180%;
    }
      }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (max-width: 600px) {
    .chart-deshboard {
        height: 80%;
    }
        }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .chart-deshboard {
        height: 20%;
    }
}

.canvas-container {
    height: 10vh;
}

.ant-checkbox-group-item {
    display: block !important;
    margin-right: 0 !important;
}
